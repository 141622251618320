<template>
    <v-container fluid>
        <section class="section-panel cover-panel">
            <div class="constraint">
                <div class="text">
                    <h1>Interactive experiences designed for children with autism.</h1>
                    <p class="font-xl mb-5">
                        Empower your child's unique journey with our interactive digital experiences. Teach them how to overcome challenges,
                        learn valuable life skills and navigate the world.
                    </p>
                    <router-link class="ux-button" :to="{ name: 'auth.signup' }"> Free Trial </router-link>
                    <router-link class="ux-button outline" :to="{ name: 'library' }"> Learn More </router-link>
                </div>
                <div class="image">
                    <v-img :src="assets.home.cover" cover />
                </div>
            </div>
        </section>
        <blockquote class="section-quote">
            <p class="quote">"A brilliant concept that delivers great results"</p>
            <p class="cite">Dani - Tantrum Hair Design</p>
        </blockquote>

        <section class="section-panel reverse">
            <div class="constraint">
                <div class="text">
                    <h1>Social Story Experiences</h1>
                    <p class="font-xl mb-5">
                        Featuring 360˚ images to explore space, voice-over to promote reading, sounds, videos and other interactivity.<br />
                        Our library is a unique resource for reducing anxiety in everyday life.
                    </p>
                    <router-link class="ux-button" :to="{ name: 'library' }"> View the library </router-link>
                </div>
                <div class="image">
                    <v-img :src="assets.home.iPadBoy" cover />
                </div>
            </div>
        </section>
        <section class="section-panel">
            <div class="constraint">
                <div class="text">
                    <h1>Interactive Visual Schedules</h1>
                    <p class="font-xl mb-5">
                        Enjoy the convenience of our beautiful pre-made Interactive Visual Schedules. With professional images and a clear
                        layout, they are perfect for printing or using on your device, anywhere, anytime.
                    </p>
                    <router-link class="ux-button" :to="{ name: 'schedule' }"> See it for yourself </router-link>
                </div>
                <div class="image">
                    <v-img :src="assets.home.schedules" cover />
                </div>
            </div>
        </section>
        <section class="section-panel reverse">
            <div class="constraint">
                <div class="text">
                    <h1>Visual Schedule Creator</h1>
                    <p class="font-xl mb-5">
                        In addition to our provided library, You can easily create and save your own visual schedules. All members get
                        access to our online tool and library of high quality images. With the ability to view on any device or save as a
                        PDF for offline use.
                    </p>
                    <router-link class="ux-button" :to="{ name: $app.user ? 'create-schedule' : 'auth.signup' }"> Try it now </router-link>
                </div>
                <div class="image">
                    <v-img :src="assets.home.builder" cover />
                </div>
            </div>
        </section>
        <section class="section-panel">
            <div class="constraint">
                <div class="text">
                    <h1>Custom Experiences</h1>
                    <p class="font-xl mb-5">
                        Whether you require something specific to your context or have a unique need, we can create it for you.
                    </p>
                    <router-link class="ux-button" :to="{ name: 'bespoke' }"> Find out more </router-link>
                </div>
                <div class="image">
                    <v-img :src="assets.home.bespoke" />
                </div>
            </div>
        </section>
        <section class="section-panel cta" v-if="!$app.user">
            <div class="constraint">
                <div class="text">
                    <!-- <h1>7 Day Free Trial</h1> -->
                    <h1>30 Day Free Trial</h1>
                    <p class="font-xl mb-5">
                        What are you waiting for? Discover more and get access to all of our member content, with a free trial today
                    </p>
                    <router-link class="ux-button dark" :to="{ name: 'auth.signup' }"> Get started today </router-link>
                </div>
            </div>
        </section>
    </v-container>
</template>
<script>
import { mapState } from "vuex";
export default {
    mixins: [],
    meta: {},
    data() {
        return {
            centered: false,
            screenWidth: "50%",
            homeImage: true,
            smallScreen: true,
            smallBtnWidth: "80%",
        };
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            if (window.innerWidth >= 960) {
                this.screenWidth = "50%";
                this.centered = false;
                this.homeImage = true;
                this.smallScreen = false;
                this.smallBtnWidth = "60%";
            } else if (window.innerWidth >= 710) {
                this.screenWidth = "100%";
                this.centered = true;
                this.homeImage = true;
                this.smallScreen = false;
                this.smallBtnWidth = "60%";
            } else if (window.innerWidth >= 281) {
                this.screenWidth = "100%";
                this.centered = true;
                this.homeImage = false;
                this.smallScreen = false;
                this.smallBtnWidth = "60%";
            } else {
                this.screenWidth = "100%";
                this.centered = true;
                this.homeImage = false;
                this.smallScreen = true;
                this.smallBtnWidth = "80%";
            }
            this.width = window.innerWidth;
        },
    },

    computed: {
        ...mapState(["assets"]),
    },
};
</script>
<style lang="scss"></style>
